export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export const capitalizeFirstLetter = (string) => {
  return typeof string === 'string'
    ? string?.charAt(0).toUpperCase() + string?.slice(1)
    : '-'
}

export const calculateDiscount = (number1, number2) => {
  return (((number2 - number1) / number2) * 100).toFixed(2)
}

export const formatNumberWithCommas = (number) => {
  return number
    ? number.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR',
      })
    : 0
}

export const formatNumberWithCommasWithoutRupee = (number) => {
  return number
    ? number.toLocaleString('en-IN', {
        maximumFractionDigits: 0,
        currency: 'INR',
      })
    : 0
}
