import './App.css'
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles'
import ScrollToTop from './components/ScrollToTop'
import { lazy, Suspense, useMemo } from 'react'
import Loader from './components/Loader'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'
import { retry } from './utils/CommonFunctions'
import { SnackbarProvider } from 'notistack'

const SideBar = lazy(() => retry(() => import('./layout/SideBar')))
const Login = lazy(() => retry(() => import('./pages/Login')))
const FourOhFour = lazy(() => retry(() => import('./pages/FourOhFour')))

const App = () => {
  const { token } = useAuthContext()

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'dark',
          background: {
            default: '#121212',
          },
          primary: {
            main: '#39B54A',
          },
          secondary: {
            main: '#B9B9B9',
          },
        },
        typography: {
          fontFamily: 'Montserrat, sans-serif',
          subtitle2: {
            color: '#B9B9B9',
          },
        },
        components: {
          MuiCard: {
            styleOverrides: {
              root: {
                backgroundColor: '#0A150C',
                border: '1.5px solid rgba(255, 255, 255, 0.08)',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.06)',
                borderRadius: '12px',
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              elevation1: {
                backgroundColor: '#0A150C',
                border: '1.5px solid rgba(255, 255, 255, 0.08)',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.06)',
                borderRadius: '12px',
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              paper: {
                backgroundColor: '#0A150C',
                border: '1.5px solid rgba(255, 255, 255, 0.08)',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.06)',
                borderRadius: '12px',
              },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: '#0A150C',
                border: '1.5px solid rgba(255, 255, 255, 0.08)',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.06)',
                borderRadius: '12px',
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                padding: '10px 30px',
                color: '#FFFFFF',
                fontWeight: 600,
                textTransform: 'capitalize',
                fontSize: '16px',
                borderRadius: '14px',
                boxShadow: 'none',
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              input: {
                borderRadius: '12px !important',
              },
              notchedOutline: {
                borderColor: '#FFFFFF14',
                borderWidth: '1.5px',
                borderRadius: '12px',
              },
            },
          },
        },
      }),
    [],
  )

  return (
    <>
      <ScrollToTop />
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <SnackbarProvider
          hideIconVariant
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route
                exact
                path="/admin/*"
                element={
                  token ? <SideBar /> : <Navigate replace to="/admin/login" />
                }
              />
              <Route
                exact
                path="/admin/login"
                element={
                  !token ? (
                    <Login />
                  ) : (
                    <Navigate replace to="/admin/dashboard" />
                  )
                }
              />
              <Route
                exact
                path="/"
                element={<Navigate replace to="/admin/login" />}
              />
              <Route
                exact
                path="/admin"
                element={<Navigate replace to="/admin/login" />}
              />
              <Route exact path="/*" element={<Navigate replace to="/404" />} />
              <Route exact path="/404" element={<FourOhFour />} />
            </Routes>
          </Suspense>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  )
}

export default App
